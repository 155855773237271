import auth0 from "auth0-js";

import { navigate } from "gatsby";

const isBrowser = typeof window !== "undefined";

// console.log('process');
// console.log(process.env)
// auth0.WebAuth(
const auth = isBrowser
    ? new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
        redirectUri: `${process.env.GATSBY_AUTH0_CALLBACK}`,
        responseType: "token id_token",
        scope: "openid profile email"
    })
    :
    {}

const tokens = {
    accessToken: false,
    idToken: false,
    expiresAt: false
}

let user = {}


export const isAuthenticated = () => {
    if (!isBrowser) {
        return;
    }

    return localStorage.getItem("isLoggedIn") === "true";
}

export const login = () => {
    if (!isBrowser) {
        return;
    }

    auth.authorize();
}

export const logout = () => {

    localStorage.setItem("isLoggedIn", false);

    auth.logout();
}

const setSession = (cb = () => {}) => (err, authResult) => {

    if (err) {
        console.log(err)
        switch(err.error) {
            case 'login_required':
                logout();
                navigate('/admin/callback');
                break;
            
            case 'invalid_token':
                logout();
                navigate('/home');
                break;

            case 'unauthorized':
                logout();
                navigate('/admin/callback');
                break;

            default:
                navigate("/admin/callback"); 
                cb();
                return false;
        }
    }
    if (authResult && authResult.accessToken && authResult.idToken) {

        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
        tokens.accessToken = authResult.accessToken
        tokens.idToken = authResult.idToken
        tokens.expiresAt = expiresAt
        user = authResult.idTokenPayload
        localStorage.setItem('Auth0-Access-Token', tokens.idToken);
        localStorage.setItem("isLoggedIn", true)
        cb()
        
        return true;
    }

    return false;
}

export const handleAuthentication = () => {

    if (!isBrowser) {
        return false;
    }

    auth.parseHash(setSession());
}

export const silentAuth = (callback) => {

    // console.log('siletly authing')

    if (!isAuthenticated()) return callback();

    auth.checkSession({}, setSession(callback));
}

export const getProfile = () => {
    return user;
}