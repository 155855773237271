import { configureStore, combineReducers } from '@reduxjs/toolkit';
import adminReducer from './adminSlice';

const rootReducer = ({
    admin: adminReducer
})

export default configureStore({
    reducer: rootReducer
});
