import "./styles.css"

import React from "react"
import { silentAuth } from "./src/utils/auth"
import store from "./src/redux/admin/store";
import { Provider } from 'react-redux';

// const socket = require("socket.io-client")("https://8485-88-107-87-200.ngrok.io:53419/socket.io/?EIO=4&transport=polling&t=O37b09T");

// socket.on("connect_error", (err) => {
//   console.log(`connect_error due to ${err.message}`);
// })

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      loading: true,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession)
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

export const wrapRootElement = ({ element }) => {

  // console.log('polled?')

  return  <Provider store={store}>
            <SessionCheck>
              {element}
            </SessionCheck>
          </Provider>
}