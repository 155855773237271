import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        netlifyToken: null,
        datoCmsApiToken: null,
        sectionsDragDisabled: false,
        sitePages: null,
        site: { name: null, id: null },
        editableForm: {},
        updatingData: false
    },
    reducers: {
        setSitePagesState: (state, {payload}) => {
            state.sitePages = payload.sitePages
        },
        setNetlifyToken: (state, { payload }) => {
            state.netlifyToken = payload.netlifyToken;
        },
        setDatoCmsApiToken: (state, { payload }) => {
            state.datoCmsApiToken = payload.datoCmsApiToken;
        },
        setSectionsDragDisabled: (state, { payload }) => {
            state.sectionsDragDisabled = payload.sectionsDragDisabled;
        },
        setSiteDetails: (state, { payload }) => {
            state.site = payload.site;
        },
        setEditableForm: (state, { payload }) => {
            state.editableForm = payload.editableForm;
        },
        setUpdatingData: (state, { payload }) => {
            state.updatingData = payload.updatingData;
        }
    }
});


export const { 
    setNetlifyToken, 
    setDatoCmsApiToken, 
    setSectionsDragDisabled, 
    setSitePagesState, 
    setSiteDetails, 
    setEditableForm,
    setUpdatingData
} = adminSlice.actions;

export default adminSlice.reducer;
